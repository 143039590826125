import { Component, ChangeDetectorRef, OnInit, OnDestroy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IFirebasePaymentMethod, IAutoCompletePrediction, IAddress, AccountPaymentService, IFirebaseAddress, FirebaseUserProfileService } from '@ztarmobile/zwp-service-backend';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { filter, Observable, takeWhile } from 'rxjs';
import { PlatformLocation } from '@angular/common';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/app.service';
import { CreditCardValidator } from '@widgets/validators/credit-card.validator';
import { NAME_PATTERN } from '@app/app.config';
import { ICreditCardInfo, IUser } from '@ztarmobile/zwp-services-auth';
import { ToastrHelperService } from '@services/toast-helper.service';
import { addPaymentModal } from '@services/modal-helper.service';

export class CreditCardContext {
  public paymentMethod: IFirebasePaymentMethod;
  public title: string;
  public note: string;
  public noteLinkText: string;
  public customClass?: string;
}

@Component({
  selector: 'app-create-cc-modal',
  templateUrl: './create-payment-method-modal.component.html',
  styleUrls: ['./create-payment-method-modal.component.scss']
})
export class CreatePaymentMethodModalComponent implements OnInit, OnDestroy {
  public context: any;
  public user: IUser;
  public billingAddress: IAddress = {} as IAddress;
  public userBillingAddress: IFirebaseAddress = {} as IFirebaseAddress;
  public expirationYearRange: number[] = [];
  public paymentInfo: ICreditCardInfo = {} as ICreditCardInfo;
  public paymentInfoForm: FormGroup;
  public billingAddressForm: FormGroup;
  public filteredOptions: Observable<Array<IAutoCompletePrediction>>;
  public filteredOptionsSubscription: Subscription;
  public userShippingAddress: IFirebaseAddress = {} as IFirebaseAddress;
  public isValidPaymentInfo = false;
  public touchAddressForm = false;
  public isValidAddress = false;
  public recaptchaResolvedSubscription: Subscription;

  private currentDate = new Date();
  private alive = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: addPaymentModal,
    public dialog: MatDialogRef<CreditCardContext>,
    private toastHelper: ToastrHelperService,
    private accountPaymentService: AccountPaymentService,
    private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private userProfileService: FirebaseUserProfileService,
    private location: PlatformLocation,
    private appState: AppState
  ) {
    this.context = data;
    this.paymentInfoForm = this.formBuilder.group({
      fullName: ['', Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z][a-zA-Z\s]*$/)])],
      cardNumber: new FormControl('', Validators.compose([Validators.required, CreditCardValidator.validateCCNumber])),
      saveCard: [''],
      cardCode: new FormControl('', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(4), Validators.pattern(/^[0-9]*$/)])),
      cardExpirationMonth: ['', Validators.required],
      cardExpirationYear: ['', Validators.required],
    }, { validator: this.validExpirationDate('cardExpirationMonth', 'cardExpirationYear') });

    this.billingAddressForm = this.formBuilder.group({
      alias: ['', Validators.compose([Validators.required, Validators.pattern(NAME_PATTERN), Validators.minLength(2), Validators.maxLength(60)])]
    });
  }

  ngOnInit(): void {
    this.location.onPopState(() => { this.beforeClose(); this.dialog.close(); });

    const year = parseInt(this.currentDate.getFullYear().toString().substr(-2), 10);
    for (let i = 0; i < 20; i++) {
      this.expirationYearRange.push(year + i);
    }    
    this.userProfileService.userProfileObservable.pipe(takeWhile(() => this.alive), filter((user) => !!user)).subscribe((user) => {
      this.user = user;
    });
  }

  public updateData(): void {
    this.paymentInfo.cardNumber = this.paymentInfoForm.get('cardNumber').value;
    this.paymentInfo.fullName = this.paymentInfoForm.get('fullName').value;
    this.paymentInfo.cardCode = this.paymentInfoForm.get('cardCode').value;
    this.billingAddress.name = this.billingAddressForm.get('alias').value;
    this.paymentInfo = Object.assign(this.paymentInfo, this.billingAddress);
    this.paymentInfo.id = '';
  }

  public setValidAddress(isValid: boolean): void {
    /* eslint-disable max-len */
    this.isValidAddress = isValid;
  }

  public addressLookUpChanged(address: IAddress): void {
    this.billingAddress = Object.assign(this.billingAddress, address);
    this.updateData();
  }

  public shippingAddressLookUpChanged(address: IAddress): void {
    this.userShippingAddress.name = this.billingAddressForm.get('alias').value;
    this.userShippingAddress = Object.assign(this.userShippingAddress, address);
  }

  public savePaymentInfo(): void {
    this.billingAddressForm.markAllAsTouched();
    this.paymentInfoForm.markAllAsTouched();
    this.touchAddressForm = true;
    if (this.paymentInfoForm.valid && this.billingAddressForm.valid && this.isValidAddress) {
      this.billingAddress.name = this.billingAddressForm.get('alias').value;
      this.paymentInfo.email = this.user.email;
      this.accountPaymentService.getExistPaymentMethod(this.paymentInfo).then((paymentMethod) => {
        if (!!paymentMethod && !!paymentMethod.id) {
          this.toastHelper.showWarning('This payment method already exists');
        } else {
          const action = 'g2g_payment_add';
          this.appState.loading = true;
          this.appState.resetAndExecuteCaptchaSubject.next({ action });
          this.paymentInfo.address1 = AccountPaymentService.shortenAddress(this.paymentInfo.address1, 30);
          this.paymentInfo.address2 = AccountPaymentService.shortenAddress(this.paymentInfo.address2, 30);
          this.paymentInfo.city = AccountPaymentService.shortenAddress(this.paymentInfo.city, 20);
          this.recaptchaResolvedSubscription = this.appState.resolvedCaptcha.subscribe(response => {
            const recaptchaResponse = response;
            if (!!recaptchaResponse) {
              this.recaptchaResolvedSubscription?.unsubscribe();
              this.callAddPayment(recaptchaResponse, true, action);
            }
          });
        }
      });
    }
  }

  public callAddPayment(captchaResponse, invisbleRecaptcha, action?): void {
    this.accountPaymentService.addPaymentMethod(this.paymentInfo, captchaResponse, false, !!action ? action : null, invisbleRecaptcha).then((methodId) => {
      this.appState.loading = false;
      this.toastHelper.showSuccess('New payment method was added successfully');
      this.paymentInfoForm.reset();
      this.billingAddress.name = ' ';
      this.closeDialog({methodId});
    }, (error) => {
      this.appState.loading = false;
      this.closeDialog({isError: true, ...error});
    });
  }

  ngOnDestroy(): void {
    this.cdRef.detach();
    this.filteredOptionsSubscription?.unsubscribe();
    this.alive = false;
    if (this.recaptchaResolvedSubscription) {
      this.recaptchaResolvedSubscription.unsubscribe();
    }
  }

  beforeClose(): boolean {
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    }
    return false;
  }

  public validExpirationDate(month: string, year: string): any {
    return (group: FormGroup): { [key: string]: any } => {
      const expMonth = group.controls[month];
      const expYear = group.controls[year];
      if (!!this.paymentInfo && !!expYear.value && !!expMonth.value) {
        this.paymentInfo.expirationDate = expMonth.value + expYear.value;
      }
      const currentYear = this.currentDate.getFullYear() % 100;
      if (!!expYear.value && !!expMonth.value) {
        if (this.currentDate.getMonth() + 1 > +expMonth.value && currentYear >= +expYear.value) {
          return {
            cardExpirationInvalid: true
          };
        }
      }
    };
  }

  public closeDialog(action?: any): void {
    this.beforeClose();
    this.dialog.close(action);
  }
}
