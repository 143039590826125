<div class="modal">
    <div class="container model-container failure-modal {{context.customClass}}">
        <div class="modal-header">
            <div class="failure-image">
                <img src="/assets/icon/failure-icon.svg" alt="Failure Icon" width="100" height="100">
            </div>
            <div class="modal-heading">
                <h3 class="header" *ngIf="context?.title">{{context.title}}</h3>
            </div>
        </div>
        <div class="modal-body">
            <div class="modal-content">
                <div class="row">
                    <p *ngIf="!!context.message" class="failure-message">
                        {{context.message}}
                    </p>
                    <div *ngIf="!!context.customHTML" [innerHTML]="context.customHTML"></div>
                </div>
                <div class="row modal-actions">
                    <button class="button primary" (click)="done()">{{context.buttonText}}</button>
                </div>
            </div>
        </div>
    </div>
    </div>