<div [innerHtml]="jsonLDString"></div>
<div [innerHtml]="plansJsonLDString"></div>
<div class="home-page">
  <section class="home-banner-section">
    <app-banners></app-banners>
  </section>
  <section class="plans-section">
    <div class="page-section">
      <h1 class="title">Choose the Bundle that better fits your needs!</h1>
      <div class="view-plans-part" data-cy="viewAllPlans" (click)="goToPlans()">
        <p class="link-text">View All Plans</p>
        <img src="/assets/icon/primary-circle-arrow.svg" class="arrow" alt="Circle Arrow Icon" width="20" height="20" loading="lazy">
      </div>
    </div>
    <div class="plan-cards-part">
      <div class="desktop-plans">
        <ng-container *ngFor="let plan of featuredPlans; let i = index;">
          <div class="plan-card" (click)="cardIndex = i"
            [ngClass]="{'center-card-margin': i === 1, 'focus': cardIndex === i}">
            <div class="card-header">
              <h4 class="header">Starting at <span class="price-offer">${{calculatePlanPricePerMonth(plan)}}/mo</span></h4>
            </div>
            <div class="card-content">
              <div class="price-part">
                <p class="price" [class.alt-font]="plan?.data > 20480">{{plan?.data > 20480 ? 'UNLIMITED'
                  :(plan?.data/1024 )+'GB'}}</p>
              </div>
              <p class="smaller"><span class="auto-pay-text">High Speed Data</span></p>
              <hr class="border">
              <ul class="plan-details">
                <li class="detail last">
                  <p class="smallest"><b>+ Unlimited</b> talk and text + data access on America’s nationwide networks</p>
                </li>
              </ul>
              <button class="button primary" (click)="goToPlanDetails(plan?.data)" aria-label="Shop Plan">Shop Plan</button>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="tablet-plans">
        <div class="plan-data-section">
          <div class="items" *ngFor="let item of planData" [class.selected]="item === selectedPlanData">
            <p class="item-data" (click)="changeSelectedPlan(item)">{{item > 20480 ? 'UNLIMITED' :(item /1024 )+'GB'}}</p>
            <img *ngIf="item === selectedPlanData" src="/assets/icon/triangle-primary-arrow-down.svg" alt="triangle" width="37" height="20" class="triangle-arrow">
          </div>
        </div>
        <div class="plan-card">
            <div class="card-header">
              <h4 class="header">Starting at <span class="price-offer">${{calculatePlanPricePerMonth(selectedFeaturedPlan)}}/mo</span></h4>
            </div>
            <div class="card-content">
              <div class="price-part">
                <p class="price" [class.alt-font]="selectedFeaturedPlan?.data > 20480">{{selectedFeaturedPlan?.data > 20480 ? 'UNLIMITED'
                  :(selectedFeaturedPlan?.data/1024 )+'GB'}}</p>
              </div>
              <p class="smaller"><span class="auto-pay-text">High Speed Data</span></p>
              <hr class="border">
              <ul class="plan-details">
                <li class="detail last">
                  <p class="smallest"><b>+ Unlimited</b> talk and text + data access on America’s nationwide networks</p>
                </li>
              </ul>
              <button class="button primary" (click)="goToPlanDetails(selectedFeaturedPlan?.data)" aria-label="Shop Plan">Shop Plan</button>
            </div>
          </div>
      </div>
      <div class="plans-swiper-container swiper-container">
        <div class="swiper-wrapper">
          <ng-container *ngFor="let plan of featuredPlans; let i = index;">
            <div class="swiper-slide plan-card" (click)="cardIndex = i"
              [ngClass]="{'center-card-margin': i === 1, 'focus': cardIndex === i}">
              <div class="card-header">
                <h4 class="header">Starting at <span class="price-offer">${{calculatePlanPricePerMonth(plan)}}/mo</span></h4>
              </div>
              <div class="card-content">
                <div class="price-part">
                  <p class="price" [class.alt-font]="plan?.data > 20480">{{plan?.data > 20480 ? 'UNLIMITED'
                    :(plan?.data/1024 )+'GB'}}</p>
                </div>
                <p class="smaller"><span class="auto-pay-text">High Speed Data</span></p>
                <hr class="border">
                <ul class="plan-details">
                  <li class="detail">
                    <img width="12" height="12" src="/assets/icon/primary-check-mark.svg" alt="Check Mark" loading="lazy">
                    <p class="smallest">Total price reflects <b>$5</b> discount with AutoPay Credit.</p>
                  </li>
                  <li class="detail last">
                    <img width="12" height="12" src="/assets/icon/primary-check-mark.svg" alt="Check Mark" loading="lazy">
                    <p class="smallest"><b>Unlimited</b> talk and text + data access on America’s nationwide networks
                    </p>
                  </li>
                </ul>
                <button class="button primary" (click)="goToPlanDetails(plan?.data)" aria-label="Shop Plan">Shop Plan</button>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="hand-swiper">
          <img src="/assets/icon/hand-swipe.svg" alt="Hand Swipe" width="32" height="32" loading="lazy">
          <p class="caption">Swipe right</p>
        </div>
        <ul class="slick-dots plan-dots slider-pagination plans-slider-pagination"></ul>
      </div>
    </div>
  </section>
  <section class="content-flex-section page-section">
    <picture class="image-part">
      <source width="320" height="320" srcset='assets/img/women-with-phone.svg' media="(min-width: 320px)">
      <img width="320" height="320" src="assets/img/women-with-phone.svg" alt="American Women With Mobile Phone" loading="lazy">
    </picture>
    <div class="content-part">
      <h2 class="title">Bring your Phone & Keep your Number</h2>
      <p class="sub-title">Bring the phone you love to Good2Go Mobile, and select your new plan!</p>
      <button class="button primary" id="Bring-phone" data-cy="Bring-phone"
        [routerLink]="ACTIVATION_ROUTE_URLS.BASE + '/' + ACTIVATION_ROUTE_URLS.CHECK_PHONE" title="Check compatibility"
        value="Bring phone" aria-label="Bring phone">Check Compatibility</button>
    </div>
  </section>
  <section class="content-flex-section referral page-section">
    <div class="content-part">
      <h2 class="title">Refer a Friend, you each get $10!</h2>
      <p class="sub-title">Sharing is rewarding! Invite your friends to join us now and save up to $250 a year!</p>
      <button class="button primary" id="start-referring" 
        [routerLink]="['/' + ROUTE_URLS.REFER_FRIEND]"
        title="Start Referring" value="Start Referring" aria-label="Start Referring">Start Referring</button>
    </div>
    <picture class="image-part">
      <source width="320" height="320" srcset='assets/img/refer-section.svg' media="(min-width: 320px)">
      <img width="320" height="320" src="assets/img/refer-section.svg" alt="Refer your friend" loading="lazy ">
    </picture>
  </section>
  <section class="content-flex-section coverage page-section">
    <picture class="image-part">
      <source width="396" height="250" srcset='assets/img/home-US-map.svg' media="(min-width: 320px)">
      <img width="396" height="250" src="assets/img/home-US-map.svg" alt="USA Map" loading="lazy">
    </picture>
    <div class="content-part">
      <h2 class="title">Powered by America’s Best Networks</h2>
      <p class="sub-title">We’re powered by two of the country’s largest <b>5G - 4G LTE carriers</b>, covering 99% of
        America.</p>
      <button class="button primary" data-cy="checkYourCoverage"
        [routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.LANDING_COVERAGE]" title="Check Your Coverage"
        value="Check Your Coverage" aria-label="Check Your Coverage">Check Your Coverage</button>
    </div>
  </section>
  <section class="feedback-section">
    <div class="feedback-titles page-section">
      <h2 class="section-title">What Do Our Customers Say?</h2>
      <p class="info">We grow as individuals by contributing the best to community</p>
    </div>
    <div class="cards-section">
      <div class="testimonial-swiper-container swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide feedback-card" *ngFor="let slide of slides">
            <div class="images-row">
              <img class="avatar" alt="avatar" [src]="slide?.fields?.avatar?.fields?.file?.url" width="80"
                height="80" loading="lazy" />
              <img class="stars" alt="stars" src="assets/img/stars.svg" width="100" height="20" loading="lazy"/>
            </div>
            <p class="content">{{slide?.fields?.userMessage}}</p>
            <div class="details-section">
              <div class="user">
                <p class="name">{{slide?.fields?.userDetails.split(',')[0]}}</p>
                <p class="state">{{slide?.fields?.userDetails.split(',')[1]}}</p>
              </div>
              <div class="testemonial">
                <img src="assets/img/like.svg" alt="like" width="16" height="16" loading="lazy" />
                <p class="title">Testimonial</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="backdrop">
        <div class="swipe">
          <img src="assets/img/swipe.svg" alt="swipe left and right" width="28" height="35" />
          <p class="swipe-note">swipe right and left</p>
        </div>
      </div>
    </div>
    <div class="swiper-button-next testimonial-swiper-button-next"></div>
    <div class="swiper-button-prev testimonial-swiper-button-prev"></div>
  </section>
</div>