<div class="modal">
  <div class="container model-container add-number-modal {{context.customClass}}">
    <div class="modal-header">
      <h4 class="modal-heading" *ngIf="!successResult && !failureResult">Add an activated number</h4>
      <button class="modal-close modal-toggle" (click)="cancel()"><i class="icon-close"></i></button>
    </div>
    <div class="modal-body">
      <div class="modal-content" *ngIf="!successResult && !failureResult">
        <ng-container *ngIf="!waitingConfirmCode">
          <form [formGroup]="activatingPhoneForm" class="phone-number-section">
            <p class="subtitle">Enter your activated Good2Go Phone Number:</p>
            <div class="specific-width">
              <label class="outlined-label-input">
                <input placeholder=" " formControlName="phoneNumber" id="phoneNumber" data-cy="phoneNumber" type="tel"
                  required minlength="10" maxlength="10" restrictNumbers />
                <span>Phone Number</span>
              </label>
              <label class="validation-message"
                *ngIf="activatingPhoneForm.controls.phoneNumber.hasError('required') && activatingPhoneForm.controls.phoneNumber.touched">
                Phone number is required
              </label>
              <label class="validation-message"
                *ngIf="!!activatingPhoneForm.controls.phoneNumber.value && activatingPhoneForm.controls.phoneNumber.hasError('minlength')">
                Phone number is invalid
              </label>
            </div>
          </form>
          <button class="button primary" (click)="sendCode()" type="submit" id="Confirm">Confirm
          </button>
        </ng-container>
        <ng-container *ngIf="!!waitingConfirmCode">
          <p class="confirm-code-title">We’ve sent a verification code to your Phone Number:</p>
          <p class="confirm-code-phone"><b>{{phoneNumber | phone}} </b></p>
          <form [formGroup]="verificationForm" class="verification-section">
            <div class="specific-width">
              <label class="outlined-label-input">
                <input placeholder=" " formControlName="verificationCode" id="verificationCode" type="tel" required
                  minlength="6" maxlength="6" restrictNumbers />
                <span>Verification Code</span>
              </label>
              <label class="validation-message"
                *ngIf="verificationForm.controls.verificationCode.hasError('required') && verificationForm.controls.verificationCode.touched">
                Verification code is required
              </label>
              <label class="validation-message"
              *ngIf="!!verificationForm.controls.verificationCode.value && verificationForm.controls.verificationCode.hasError('minlength')">
              Verification code is invalid
            </label>
            </div>
          </form>
          <button class="button primary" (click)="verifyLoginCode()">Verify &
            Proceed
          </button>
        </ng-container>
      </div>
      <div class="modal-results" *ngIf="!!failureResult && customFailureResult">
        <img src="/assets/icon/failure-res.svg" width="100"
          height="100" alt="result img">
        <h4 class="title">Invalid verification code</h4>
        <div class="inner-content">
          <p class="end-note">Recheck your entries and try again</p>
          </div>
          <button class="button primary" (click)="recheckIntries()">Got it</button>
      </div>
    </div>
  </div>
</div>
