<div class="modal">
  <div class="container model-container {{context.customClass}}">
    <div class="modal-header">
      <h2 class="modal-heading">{{context.title}}</h2>
      <button class="modal-close modal-toggle" (click)="closeDialog()"><i class="icon-close"></i></button>
    </div>
    <div class="modal-body">
      <form [formGroup]="emailForm">
        <div class="modal-content">
          <p class="message smaller">
            {{context.message}}
          </p>
          <fieldset>
            <label class="outlined-input">
              <input type="email" formControlName="email" required autocomplete="email" placeholder=" ">
              <span>Enter New Email</span>
            </label>
            <label class='validation-message alert' *ngIf="emailForm.controls.email.hasError('pattern')"
              id="invalid-email-msg" data-cy="nvalidEmailMsg">
              Invalid email. Try again!
            </label>
            <label class='validation-message alert'
              *ngIf="emailForm.controls.email.hasError('required') && emailForm.controls.email.touched"
              id="required-email-msg" data-cy="required-email-msg">
              New email is required
            </label>
            <label class='validation-message alert'
              *ngIf="emailForm.get('email')?.errors?.['forbiddenEmail'] && emailForm.get('email')?.touched">
              Your new email must be different from your previous email.
            </label>
          </fieldset>
          <div class="modal-actions">
            <button class="button primary" (click)="submitEmail()">{{context?.okBtnText || 'Update'}}</button>
            <button class="button tertiary" (click)="closeDialog()">{{context?.cancelBtnText || 'Cancel'}}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
