<div class="modal">
<div class="container model-container {{context.customClass}}">
  <div class="modal-header">
    <h4 class="modal-heading">Confirmation</h4>
    <button class="modal-close modal-toggle" (click)="closeDialog()"><i class="icon-close"></i></button>
  </div>
  <div class="modal-body">
    <div class="modal-content">
      <p>You are about to swap to eSIM</p>
      <p class="iccid">({{context.iccid}})</p>
      <p>on Phone Number</p>
      <p class="text-bold">{{context.mdn | phone }}</p>
      <div class="modal-actions">
        <button class="button primary" (click)="confirm()" aria-label="proceed">Proceed</button>
      </div>
    </div>
  </div>
</div>
</div>
