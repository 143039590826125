import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PlatformLocation } from '@angular/common';
import { NgForm } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { ToastrHelperService } from '@services/toast-helper.service';
import { AppState } from '@app/app.service';

export class ConfirmPasswordModalContext {
  public message: string;
  public title: string;
  public customClass?: string;
}

@Component({
  selector: 'app-confirm-password-modal',
  templateUrl: './confirm-password-modal.component.html'
})
export class ConfirmPasswordModalComponent {
  @ViewChild('confirmPasswordForm') confirmPasswordForm: NgForm;
  public context: any;
  public confirmCurrentPassword: string;
  public showPassword = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,public dialog: MatDialogRef<ConfirmPasswordModalContext>,
    private location: PlatformLocation,
    private toastHelper: ToastrHelperService,
    private angularFireService: AngularFireAuth,
    private appState: AppState,
  ) {
    location.onPopState(() => {this.beforeDismiss();this.dialog.close();});
    this.context = data;
  }

  public togglePassword(): void {
    this.showPassword = !this.showPassword
  }

  beforeClose(): boolean {
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    }
    return false;
  }

  beforeDismiss(): boolean {
    return this.beforeClose();
  }

  closeDialog(): void {
    this.beforeDismiss();
    this.dialog.close();
  }

  submitPassword(): void {
    this.confirmPasswordForm.form.markAllAsTouched();
    if(this.confirmPasswordForm.valid) {
      this.appState.loading = true;
      this.reauthenticateUser(this.confirmCurrentPassword).then(() => {
        this.appState.loading = false;
        this.beforeDismiss();
        this.dialog.close(this.confirmCurrentPassword);  
      }).catch((error) => {
        this.appState.loading = false;
        this.toastHelper.showAlert('The password is invalid');
      })
    }
  }

  // Function to reauthenticate user using their current password
  reauthenticateUser(password: string) {
    return this.angularFireService.currentUser.then((user) => {
      if (!user) {
        throw new Error('No user is signed in.');
      }
      const credential = firebase.auth.EmailAuthProvider.credential(user.email!, password);
      return user.reauthenticateWithCredential(credential);
    });
  }
}
