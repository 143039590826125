<form [formGroup]="addressFieldsForm">
  <div class="form-section">
    <fieldset class="address-lookup address-name" *ngIf="showAlias">
      <label class="outlined-input">
        <input id="addressAlias"  data-cy="addressName" type="text" formControlName="alias"
          (change)="addressFieldChanged($event)" [readonly]="readonly" maxlength="50"
          (input)="validateAlias(addressFieldsForm.controls.alias.value)" placeholder=" ">
        <span>Name<span class="star">*</span></span>
      </label>

      <div class='form-text error'>
        <label class="validation-message" data-cy="addressNameRequiredMsg" id="addressNameRequiredMsg" *ngIf="addressFieldsForm.controls.alias.touched && !addressFieldsForm.controls.alias.value && addressFieldsForm.controls.alias.hasError('required')">Address name is a required field</label>
        <label class="validation-message" data-cy="addressNameInvalidMsg" id="addressNameInvalidMsg" *ngIf="addressFieldsForm.controls.alias.dirty && !!addressFieldsForm.controls.alias.value && !isValidName">Name is invalid</label>
      </div>
    </fieldset>

    <div class="form-row">
      <fieldset class="address-lookup col-4">
        <label class="outlined-input">
          <input  formControlName="address1" matInput type="search" list-formatter="description"  
            id="addressLookup" data-cy="addressLookup" #addressLookup type="search"
            class="geolocate"  [matAutocomplete]="auto" required (input)="changedAddress()" 
            (change)="validateAddress1(addressFieldsForm.controls.address1.value)" [readonly]="readonly" placeholder=" ">
          <span>Address Line<span class="star">*</span></span>
        </label>

        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addressDetails($event)">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{ option?.description }}
          </mat-option>
        </mat-autocomplete>     
        <div class='form-text error'>
          <label id ="billingAddress-msg" data-cy="addressIsRequiredMSG" class="validation-message" data-cy="addressRequiredMsg" *ngIf="addressFieldsForm.controls.address1.touched && addressFieldsForm.controls.address1.hasError('required')">Address is a Required Field</label>
        </div>
      </fieldset>

      <fieldset class="col-2">
        <label class="outlined-input">
          <input id="address2" data-cy="suiteNo" type="text" formControlName="address2" [readonly]="readonly"
            (change)="addressFieldChanged($event)" autocomplete="address-line2" placeholder=" ">
          <span>Suite/Apt. No</span>
        </label>
      </fieldset>
    </div>
  </div>

  <div class="form-row last" [class.active]="hasDetails" [class.hidden]="!hasDetails">
    <div class="col-4">
      <fieldset class="city">
        <label class="outlined-input">
          <input id="billingCity"  data-cy="billingCity" type="text" formControlName="city" [readonly]="readonly"
          (change)="addressFieldChanged($event)" autocomplete="address-level2" placeholder=" ">
          <span>City<span class="star">*</span></span>
        </label>

        <div class='form-text error'>
          <label id="city-msg" data-cy="cityIsRequired" class="validation-message" *ngIf="addressFieldsForm.controls.city.touched && addressFieldsForm.controls.city.hasError('required')">City is a Required Field</label>
        </div>
        <div class='form-text error'
          *ngIf="addressFieldsForm.controls.city.dirty && addressFieldsForm.controls.city.hasError('pattern')">
          <label id="city-invalid-msg" data-cy="invalidCityMsg" class="validation-message">Invalid City </label>
        </div>
      </fieldset>

      <fieldset class="state">
        <label class="outlined-input">
          <input id="billingState" data-cy="billingState" type="text" formControlName="state" [readonly]="readonly"
          (change)="addressFieldChanged($event)" autocomplete="address-level1" maxlength="2" minlength="2" placeholder=" ">
          <span>State<span class="star">*</span></span>
        </label>

        <div class='form-text error' *ngIf="addressFieldsForm.controls.state.touched && addressFieldsForm.controls.state.hasError('required')">
          <label id="state-msg" data-cy="stateIsRequired" class="validation-message">State is a Required Field </label>
        </div>
        <div class='form-text error'
          *ngIf="addressFieldsForm.controls.state.dirty && !!addressFieldsForm.controls.state.value && !addressFieldsForm.controls.state.valid">
          <label id="state-invalid-msg" data-cy="invalidStateMsg" class="validation-message">Invalid State </label>
        </div>
      </fieldset>
    </div>


    <fieldset class="col-2">
      <label class="outlined-input">
        <input id="billingPostal"  data-cy="billingPostal" type="text" formControlName="postalCode" [readonly]="readonly"
        (change)="addressFieldChanged($event)" autocomplete="postal-code" maxlength="10" minlength="5" placeholder=" ">
        <span>Postal Code<span class="star">*</span></span>
      </label>

      <div class='form-text error'>
        <label id="postalCode-msg" class="validation-message" data-cy="postalIsRequired" *ngIf="addressFieldsForm.controls.postalCode.touched && addressFieldsForm.controls.postalCode.hasError('required')">Postal Code is a Required Field</label>
      </div>
      <div class='form-text error'
        *ngIf="addressFieldsForm.controls.postalCode.dirty && addressFieldsForm.controls.postalCode.hasError('pattern')">
        <label id="postalCode-invalid-msg" data-cy="invalidPostalCodeMsg" class="validation-message">Invalid Postal Code </label>
      </div>
    </fieldset>
  </div>
  <input type="hidden" formControlName="country" autocomplete="country">
</form>