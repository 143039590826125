<div class="modal">
  <div class="container model-container {{context.customClass}}">
    <div class="modal-header">
      <h4 class="modal-heading">{{context?.title || ''}}</h4>
      <button id="close-popup-btn" data-cy="closeIcon" class="modal-close modal-toggle" (click)="closeDialog()"><i
          class="icon-close"></i></button>
    </div>
    <div class="modal-body">
      <div class="modal-content">
        <form [formGroup]="paymentInfoForm" class="payment-form">
          <p class=" text-bold title">Card Information</p>
          <fieldset class="full-width">
            <label class="outlined-input">
              <input id="fullName" data-cy="fullName" type="text" name="fullName" formControlName="fullName"
                (keyup)="updateData()" (change)="updateData()" autocomplete="cc-given-name" minlength="1" maxlength="30"
                placeholder=" ">
              <span>Name on Card<span class="star">*</span></span>
            </label>
            <label class="validation-message" data-cy="nameOnCardRequiredMsg"
              *ngIf="paymentInfoForm.controls.fullName.touched && paymentInfoForm.controls.fullName.hasError('required')">Name
              on card
              is required. </label>
            <label class="validation-message" data-cy="invalidName"
              *ngIf="paymentInfoForm.controls.fullName.dirty && paymentInfoForm.controls.fullName.errors?.pattern">Invalid
              name. </label>
          </fieldset>

          <fieldset class="full-width card-number-field">
            <label class="outlined-input">
              <input id="cardNumber" data-cy="cardNumber" type="tel" name="cardNumber" ccNumber
                formControlName="cardNumber" (keyup)="updateData()" (change)="updateData()" maxlength="19"
                autocomplete="cc-number" placeholder=" ">
              <span>Card Number<span class="star">*</span></span>
            </label>
            <label class="validation-message" data-cy="requiredCardNoMsg"
              *ngIf="paymentInfoForm.controls.cardNumber.touched && !paymentInfoForm.controls.cardNumber.value">Card
              number
              is required. </label>
            <label class="validation-message"
              *ngIf="paymentInfoForm.controls.cardNumber.dirty && !paymentInfoForm.controls.cardNumber.valid && paymentInfoForm.controls.cardNumber.value"
              data-cy="invalidCreditCardMsg">Invalid
              Credit Card Number. </label>
          </fieldset>

          <div class="flex-fields">
            <fieldset>
              <div class="outlined-select"
                [class.contain-value]="!!paymentInfoForm?.controls?.cardExpirationMonth.value">
                <select id="cardExpirationMonth" data-cy="cardExpirationMonth" name="cardExpirationMonth"
                  formControlName="cardExpirationMonth" autocomplete="cc-exp-month" (change)="updateData()">
                  <option value="" selected disabled hidden></option>
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
                <span translate>
                  Month<p class="star">*</p>
                </span>
              </div>
              <label class="validation-message no-wrap" data-cy="expiryMonthRequiredMsg"
                *ngIf="(paymentInfoForm.controls.cardExpirationYear.touched || paymentInfoForm.controls.cardExpirationMonth.touched) && paymentInfoForm.controls.cardExpirationMonth.hasError('required')">
                Expiration month is required
              </label>
            </fieldset>

            <fieldset>
              <div class="outlined-select"
                [class.contain-value]="!!paymentInfoForm?.controls?.cardExpirationMonth.value">
                <select id="cardExpirationYear" data-cy="cardExpirationYear" name="cardExpirationYear"
                  formControlName="cardExpirationYear" autocomplete="cc-exp-year" (change)="updateData()"
                  aria-placeholder="Year">
                  <option value="" selected disabled hidden></option>
                  <option *ngFor="let expYear of expirationYearRange" value="{{expYear}}">
                    {{expYear}}
                  </option>
                </select>
                <span translate>
                  Year<p class="star">*</p>
                </span>
              </div>
              <label class="validation-message no-wrap" data-cy="expiryYearRequiredMsg"
                *ngIf="(paymentInfoForm.controls.cardExpirationYear.touched || paymentInfoForm.controls.cardExpirationMonth.touched) && paymentInfoForm.controls.cardExpirationYear.hasError('required')">
                Expiration year is required
              </label>
              <label class="validation-message no-wrap" data-cy="expiryDateRequiredMsg"
                *ngIf="(paymentInfoForm.controls.cardExpirationMonth.touched || paymentInfoForm.controls.cardExpirationYear.touched) && paymentInfoForm.errors?.cardExpirationInvalid && (paymentInfoForm.controls.cardExpirationMonth.valid || !paymentInfoForm.controls.cardExpirationYear.valid)">
                Expiration date must be valid
              </label>
            </fieldset>

            <fieldset class="ccv">
              <label class="outlined-input">
                <input id="cardCode" data-cy="cardCode" type="password" name="cardCode" restrictNumbers
                  formControlName="cardCode" minlength="3" maxlength="4" (keyup)="updateData()" (change)="updateData()"
                  autocomplete="cc-csc" placeholder=" ">
                <span>CVV<span class="star">*</span></span>
              </label>
              <label class="validation-message" data-cy="cvvRequiredMsg"
                *ngIf="paymentInfoForm.controls.cardCode.touched && !paymentInfoForm.controls.cardCode.value">CVV
                is
                required field. </label>
              <label class="validation-message" data-cy="cvvInvalidMsg"
                *ngIf="paymentInfoForm.controls.cardCode.dirty && paymentInfoForm.controls.cardCode.value && !paymentInfoForm.controls.cardCode.valid">CVV
                is invalid. </label>
            </fieldset>
          </div>
        </form>

        <form [formGroup]="billingAddressForm" class="address-form">
          <p class="text-bold title">Billing address</p>
          <fieldset>
            <label class="outlined-input">
              <input type="text" formControlName="alias" autocomplete="alias" id="alias" data-cy="alias"
                placeholder=" ">
              <span>Address Name<span class="star">*</span></span>
            </label>
            <label id="required-alias-msg" data-cy="addressNameReuiredMsg" class="validation-message"
              *ngIf="billingAddressForm.controls.alias.touched && !billingAddressForm.controls.alias.value">Name
              is required field</label>
            <label id="invalid-alias-msg" data-cy="addressNameInvalidMsg" class="validation-message"
              *ngIf="billingAddressForm.controls.alias.dirty && !billingAddressForm.controls.alias.valid">
              Name
              is invalid</label>
          </fieldset>

          <fieldset class="address-lookup">
            <app-address-lookup [displayedAddress]="userBillingAddress" (isValid)="setValidAddress($event)"
              (addressChange)="addressLookUpChanged($event)" [touchForm]="touchAddressForm">
            </app-address-lookup>
          </fieldset>
        </form>
        <div class="modal-actions">
          <button class="button primary" *ngIf="!!context?.cancelBtnText" (click)="savePaymentInfo($event)">
            {{ context.okBtnText }}
          </button>
          <button class="button tertiary" *ngIf="!!context?.cancelBtnText" (click)="closeDialog()">
            {{ context.cancelBtnText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
