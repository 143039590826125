<div class="banner-container">
  <div class="banners-swiper-container">
    <div class="banner-section">
      <picture class="banner-image-container">
        <source width="639" height="520" srcset="assets/img/banners/spring-banner-mobile.webp"
          media="(max-width: 639.9px)" type="image/webp">
        <source width="639" height="520" srcset="assets/img/banners/spring-banner-mobile.jpg"
          media="(max-width: 639.9px)" type="image/jpg">
        <source width="1024" height="500" srcset="assets/img/banners/spring-banner-tablet.webp"
          media="(max-width: 1024.9px)" type="image/webp">
        <source width="1024" height="500" srcset="assets/img/banners/spring-banner-tablet.jpg"
          media="(max-width: 1024.9px)" type="image/jpg">
        <source width="2000" height="500" srcset="assets/img/banners/spring-banner.webp" media="(min-width: 1025px)"
          type="image/webp">
        <source width="2000" height="500" srcset="assets/img/banners/spring-banner.jpg" media="(min-width: 1025px)"
          type="image/jpg">
        <img width="2000" height="500" src="assets/img/banners/spring-banner.jpg" alt="Unlimited Talk, Text & Data for $25 "
          title="Unlimited Talk, Text & Data for $25" loading="eager">
      </picture>
      <div class="banner-content-container">
        <h1 class="caption-text">$25/mo <span class="for">for</span></h1>
        <h1 class="header">UNLIMITED</h1>
        <h1 class="subheader secondary-font">Talk, Text & Data</h1>
        <button class="button primary" (click)="goToOfferLanding()" aria-label="Shop Today">Shop Now!</button>
      </div>
    </div>
  </div>
</div>
