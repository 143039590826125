<div class="modal">
<div class="container model-container {{context.customClass}}">
    <div class="modal-header">
        <div class="success-image">
            <img src="/assets/icon/modal-success-image.svg" alt="Success Icon">
        </div>
        <div class="modal-heading">
            <h3 class="header" *ngIf="context?.title">{{context.title}}</h3>
        </div>
    </div>
    <div class="modal-body">
        <div class="modal-content">
            <div class="row">
                <p *ngIf="!!context.message" class="success-message">
                    {{context.message}}
                </p>
                <div *ngIf="!!context.customHTML" [innerHTML]="context.customHTML"></div>
            </div>
            <div class="row modal-actions">
                <button class="button primary" (click)="done()">{{context.buttonText}}</button>
            </div>
        </div>
    </div>
</div>
</div>