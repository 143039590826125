export const ROUTE_URLS = {
  // eslint-disable @typescript-eslint/naming-convention
  PARAMS: {
    REFERENCE_PAGE: 'refPage',
    PRODUCT_ID: 'productId',
    PHONE_ID: 'phoneId',
    CATEGORY_ID: 'categoryId',
    USER_PLAN_ID: 'userPlanId',
    SELECTED_PLAN: 'selectedPlan',
    PHONE_NUMBER: 'phoneNumber',
    ACTIVATE_SIM_CARD: 'activateSimCard',
    UPDATE_PLAN: 'updatePlan',
    FAQ_QUESTION_ID: 'questionId',
    REFERRAL_ID: 'referralCode',
    USER_ORDER_ID: 'orderId',
    DATA_SETUP_LANDING: 'dataSetupLanding',
    INTERNATIONAL_CALL_LANDING: 'rates',
    PLAN_ADD_ON_ID: 'planAddonId',
    PHONE_OS: 'os',
    SELECTED_PLAN_ID: 'selectedPlanID',
    PLAN_ID_FROM_EMAIL: 'planIDFromEmail',
    AUTO_RENEW: 'autoRenew',
    PAYMENT_METHOD: 'paymentMethod',
    NEW_PURCHASE: 'newPurchase',
    UTM_SOURCE: 'utm_source',
    UTM_MEDIUM: 'utm_medium',
    UTM_CAMPAIGN: 'utm_campaign',
    UTM_CONTENT: 'utm_content',
    NETWORK: 'network',
    PHONE_PURCHASE: 'phonePurchase',
    COVERED: 'covered',
    PAYMENT_UPDATED: 'paymentUpdated',
    CART: 'cart',
    FINANCING: 'financing',
    OFFER_PLAN: 'offerPlan'
  },
  HOME: 'home',
  BRING_PHONE: 'bring-your-phone',
  WIFI_CALLING: 'wifi-calling-use-a-wifi-network-to-make-calls',
  HD_VOICE: 'get-better-voice-quality-with-hd-voice-volte',
  REFER_FRIEND: 'refer-friends-and-earn-rewards',
  EBB: 'emergency-broadband-benefit',
  ACP: 'affordable-connectivity-program',
  ACP_WINDDOWN: 'fcc-announces-affordable-connectivity-program-wind-down',
  POSTER_ACP: 'ACP',
  POSTER_ACP_SMALL: 'acp',
  PLANS: 'plans',
  DATA_SETUP: 'data-setup',
  CHECK_PHONE: 'check-phone',
  ACP_DETAIL: 'acp-details',
  ACP_DETAILS_LONG: 'free-cellphone-service-through-a-government-assistance-program-under-the-affordable-connectivity-program',
  LEGAL: {
    PRIVACY_POLICY: 'support/privacy-policy',
    TERMS_AND_CONDITIONS: 'support/terms-and-conditions',
    OPEN_INTERNET: 'support/open-internet',
    PRIVACY_NOTICE: 'support/privacy-notice',
    BROADBAND: 'support/broadband',
    UNLOCKING_POLICY: 'support/unlocking-policy',
    INTERNATIONAL_TERMS_AND_CONDITIONS: 'support/international-calling-terms-and-conditions',
    INTERNATIONAL_PRIVACY_POLICY: 'support/international-calling-privacy-notice',
    EULA: 'support/eula'
  },
  LOGIN: 'login'
};
export const OFFERS_ROUTE_URLS = {
  BASE: 'offers',
  UPGRADE_OFFER: 'upgrade-offer',
  CONFIRM_UPGRADE: 'upgrade-confirmation',
  MONEY_SAVING_LANDING_1G: '1gb-wireless-plan-50-percent-off',
  MONEY_SAVING_LANDING_3G: '3gb-wireless-plan-50-percent-off',
  DOUBLE_DATA_HOLIDAY_2G: '2gb-wireless-plan-double-data-holiday-season-offer',
  DOUBLE_DATA_HOLIDAY_3G: '3gb-wireless-plan-double-data-holiday-season-offer',
  DOUBLE_DATA_HOLIDAY_6G: '6gb-wireless-plan-double-data-holiday-season-offer',
  DOUBLE_DATA_2G: '2gb-wireless-double-data-offer',
  DOUBLE_DATA_3G: '3gb-wireless-double-data-offer',
  DOUBLE_DATA_6G: '6gb-wireless-double-data-offer',
  MONEY_SAVING_1G: '1gb-wireless-data-offer',
  MONEY_SAVING_3G: '3gb-wireless-data-offer',
  WHISTLEOUT_4G: '4gb-wireless-data-offer',
  MEMORIAL: 'memorial-day-intro-offer',
};
export const LOGIN_ROUTE_URLS = {
  PARAMS: {
    NEXT_PAGE: 'nextPage',
    EMAIL: 'email',
    OOB_CODE: 'oobCode',
    MODE: 'mode',
    LEGACY: 'legacy'
  },
  BASE: 'user',
  WELCOME: 'welcome',
  SIGN_UP: 'sign-up',
  LOGIN: 'login',
  LEGACY_LOGIN: 'legacy-login',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  ACP_SIGNUP: 'acp-signup',
  VERIFY_EMAIL: 'verify-email'
};
export const ACCOUNT_ROUTE_URLS = {
  PARAMS: {
    NEXT_PAGE: 'nextPage',
    INTERNATIONAL_CALLING: 'internationalCalling',
    CHANGE_ADDON: 'changeAddon',
    ORDER_ID: 'id',
    TICKET_NUMBER: 'ticketNumber',
    FROM_DETAILS: 'fromDetails',
    PLAN_ID: 'planId',
    MANAGE_CARDS: 'manageCards',
    MANAGE_DEVICE_DATA: 'manageDeviceData',
    MANAGE_DEVICE: 'manageDevice',
    ADD_CARDS:'addCards'
  },
  BASE: '/account',
  NAME: 'account',
  PENDING_ACTIVATIONS: 'pending-activations',
  USAGE: 'usage',
  SUMMARY: 'summary',
  CANCEL_PLAN: 'cancel-plan',
  USER_CANCEL_PLAN_FEEDBACK: 'cancel-plan/feedback',
  PAYMENTS: 'payments',
  MANAGE_DEVICES: 'manage-devices',
  SETTINGS: 'settings',
  PAY_AND_RENEW: 'pay-and-renew',
  PLAN_ADD_ONS: 'plan-addons',
  ORDERS: 'orders',
  ACP_APPLICATION: 'acp-application',
  REPORT_ISSUE: 'orders/report-issue',
  ORDER_NOT_RECIEVED: 'orders/report-issue/order-not-recieved',
  RETURN_DEVICE: 'orders/report-issue/return-device',
  EDIT_ADDRESS: 'orders/report-issue/edit-address',
  WRONG_ITEMS: 'orders/report-issue/wrong-items',
  SOMETHING_ELSE: 'orders/report-issue/something-else',
  PACKAGE_TRACKING: 'orders/package-tracking',
  CONFIRMATION: 'orders/confirmation',
  LOGIN: 'login',
  FORGET_PASSWORD: 'forgot-password',
  SIGNUP: 'sign-up',
  REFER_FRIEND: 'refer-a-friend',
  PAYMENTS_RECEIPT_DETAILS: 'payments/receipt-details',
  ORDERS_RECEIPT_DETAILS: 'orders/receipt-details',
  ORDER_DETAILS: 'orders/details',
  ESIM_SETUP: 'esim/setup'
};
export const SHOP_ROUTE_URLS = {
  BASE: '/shop',
  NAME: 'shop',
  PLAN_DETAILS: 'plan-details',
  CUSTOMIZE_PLAN: '',
  CHECKOUT: 'checkout',
  PLACE_ORDER: 'checkout/order',
  PLANS_AND_FEATURES: 'plans',
  PLANS_SHOP: 'plans/:id/details',
  CHANGE_PLANS_SHOP: 'plans/change/:id/details',
  CHECKOUT_RESULTS: 'checkout-results',
  CHECK_PHONE: 'check-phone',
  CHANGE_SUMMARY: 'change-summary',
  CHECKOUT_SHORT: 'checkout',
  CART: 'cart',
  FOURTH_OF_JULY: 'unlimited-plans-flash-sale',
  PHONES: {
    MAIN: '',
    TYPE: 'phones',
    MODEL: 'phones/model',
    DETAILS: 'phones/details',
    SELECT_LINE: 'associate-line',
    CHECK_COVERAGE: 'phones/select/check-coverage',
    ADD_STEPS: 'phones/select',
  },
  PARAMS: {
    CHANGE_NEXT_CYCLE: 'nextCycle',
    TOP_UP_PLAN: 'topUpPlan',
    ADD_ON_PLAN: 'addOnPlan',
    ORDER_SIM: 'orderSim',
    SUCCESSFUL_PURCHASE: 'successfulPurchase',
    MDN: 'mdn',
    REFERRAL_CODE: 'referralCode',
    PHONE_PURCHASE: 'phonePurchase',
    REPLACE_PLAN: 'replacePlan',
    AUTO_RENEW: 'autoRenew',
    EDIT_SHIPPING_ADDRESS: 'editShippingAddress',
    PHONE_CHECK: 'phoneCheck',
    CYCLES: 'cycles'
  },
};

export const PLANS_SHOP_ROUTE_URLS = {
  NEW_PLAN: 'new',
  CHANGE_PLAN: 'change',
  CHANGE_PLAN_ID: 'change/:id',
  DETAILS: 'details',
  DETAILS_ID: 'details/:data',
  BASE: '/plans',
  CHANGE_SUMMARY: 'change-summary',
  PARAMS: {
    SUBSCRIPTION_CYCLE: 'subscriptionCycles',
  },
};

export const PHONES_SHOP_ROUTE_URLS = {
  BASE: 'phones',
  MODEL: 'model',
  TYPE: 'apple/iPhones',
  DETAILS: 'apple/iPhones/:phoneId',
  SELECT_LINE: 'associate-line',
  CHECK_COVERAGE: 'check-coverage',
  ADD_STEPS: 'select',
  PARAMS: {
    CHANGE_PHONE: 'changePhone'
  }
};

export const SUPPORT_ROUTE_URLS = {
  BASE: '/support',
  NAME: 'support',
  FAQS: 'faqs',
  FAQS_CATEGORY: 'faqs/:category',
  FAQS_QUESTION_ID: 'faqs/:category/:id',
  SUPPORT_CARRIER: 'faqs/support/:carrier',
  SUPPORT_QUESTION: 'faqs/support/:carrier/:id',
  SUPPORT_CATEGORY: 'faqs/support',
  COVERAGE: 'coverage',
  LANDING_COVERAGE: 'best-wireless-coverage',
  CONTACT_US: 'contact',
  CONTACT_US_RESULTS: 'contact-us-results',
  HEARING_AID_COMPATIBILITY: 'hearing-aid-compatibility',
  DATA_SETUP: 'data-setup',
  IPHONE: 'data-setup/ios',
  IPHONE4G: 'data-setup/ios/4G',
  IPHONE5G: 'data-setup/ios/5G',
  IOS17: 'data-setup/ios17',
  ANDROID: 'data-setup/android',
  ANDROID4G: 'data-setup/android/4G',
  ANDROID5G: 'data-setup/android/5G',
  TMO_IPHONE: 'data-setup/tmo/ios',
  TMO_Android: 'data-setup/tmo/android',
  IPHONE_DATA_SETUP: 'iphone-data',
  CDMA_PROGRAMMING: 'cdma-programming',
  TERMS_AND_CONDITIONS: 'terms-and-conditions',
  TERMS_AND_CONDITIONS_CATEGORY: 'terms-and-conditions/:category',
  SITEMAP: 'sitemap',
  WHY_GOOD2GO: 'whygood2go',
  STORE_LOCATOR: 'store-locator',
  ABOUT_GOOD2GO: 'aboutgood2go',
  HOW_IT_WORKS: 'how-it-works',
  INTERNATIONAL_CALLING: 'international-calling',
  REFER_A_FRIEND: 'refer-a-friend',
  PRIVACY_NOTICE: 'privacy-notice',
  NETWORK_MANAGEMENT: 'network-managmenet',
  SHUTDOWN_3G: '3G_shutdown',
  PARAMS: {
    TARGETID: 'targetId',
    NETWORKTYPE: 'networkType',
    MDN: 'mdn'
  },
};

export const CHECKOUT_ROUTE_URLS = {
  NEW_CUSTOMER: 'customerinfo/new',
  EXISTING_CUSTOMER: 'customerinfo/existing',
  SHIPPING_SECTION: 'shipping',
  PAYMENT_SECTION: 'billing',
  PLACE_ORDER: 'order',
  NAME: 'checkout',
  BASE: '/checkout'
};

export const ACTIVATION_ROUTE_URLS = {
  PARAMS: {
    PORTIN_NUMBER: 'portIn',
    ACTIVATION_CODE: 'code',
    COMPATABILE: 'compatibile',
    NETWORK: 'network',
    ZIP_CODE: 'zipCode',
    ICCID: 'iccid',
    ESIM: 'eSim',
    ESIMOnly: 'eSimOnly',
    ACTIVATION: 'activate',
    MIGRATE: 'migrate',
    ACTIVATE_PLAN: 'activatePlan',
    REVIEW: 'review'
  },
  BASE: '/activation',
  NAME: 'activation',
  CHECK_PHONE: 'check-phone',
  ACTIVATE_PLAN: 'activate-plan',
  ACTIVATE_SIM: 'activate-sim',
  CHOOSE_ACTIVATION_PATH: 'choose-activation',
  ACTIVATION_SUMMARY: 'summary',
  CHECK_PHONE_RESULT: 'check-phone/result',
  VERIFY_PHONE: '',
  CHOOSE_SIM_PATH: 'choose-sim',
  REPLACE_SIM: 'replace-sim',
  SIM_CHECK: 'sim-check',
  MIGRATE_SIM: 'migrate-sim',
  No_SIM: 'no-sim',
  CHOOSE_TRIAL: 'upgade-trial',
  ESIM_PATH: 'eSIM-activation',
  ESIM_CONFIRMATION: 'eSIM-confirmation',
  ESIM_REPLACEMENT: 'eSIM-Replacement',
  ESIM_CANCELLATION: 'cancel-eSIM',
  ESIM_START_SETUP: 'start-eSIM-setup',
  ESIM_SETUP: 'eSIM-setup',
  ESIM_SUCCESS_SETUP: 'successful-eSIM-setup',
  ACTIVATE_NEW: 'activate-new-number',
  PORT_NUMBER: 'keep-your-current-number',
  PORT_SUBMITTED: 'port-request-submitted'
};

export const MIGRATION_ROUTE_URLS = {
  BASE: '/migration',
  NAME: 'migration',
  SIM_RECEIVED: 'sim-received',
  CHECK_COMPATIBILITY: 'check-device',
  CONFIRM_DEVICE: 'confirm-device',
  COMPATIBLE_DEVIE: 'compatible-device',
  INCOMPATIBLE_DEVICE: 'incompatible-device',
  SUCCESS_ORDER: 'successful-order',
  PARAMS: {
    CONFIRMED: 'confirmed',
    ORDERED: 'ordered',
    DEVICE: 'id'
  }
};

export const ACP_ROUTE_URLS = {
  BASE: '/affordable-connectivity-program/application',
  NAME: 'affordable-connectivity-program/application',
  DETAILS: 'details',
  ENROLLMENT_NEW_LINE: 'enrollment/new-line',
  ENROLLMENT_EXISTING_LINE: 'enrollment/existing-line',
};
export const OLD_ROUTE_URL = {
  PHONES_TYPE: 'type',
  PHONES_DETAIL: 'details',
  PLANS: 'plans',
  PLAN: 'plan',
  CATAGORY: 'category1',
  OPENINTERNET: 'openInternet',
  CONTACTUS: 'contactUs',
  CONTACT: 'Contact',
  BEST_COVERAGE: 'best-wireless-coverage',
  WHYG2G: 'whygood2go',
  WHY_G2G: 'WhyGood2Go',
  HOW_IT_WORKS: 'how-it-works',
  PHONES: 'phones',
  COVERAGE: 'coverage',
  COVERAGE_CAP: 'Coverage',
  SHOP: 'shop',
  STORE_LOCATOR: 'StoreLocator',
  INTERNATIONAL_CALLING: 'international-calling',
  INTERNATIONALCALLING: 'InternationalCalling',
  INDEX: 'index.aspx',
  DEVICES: 'devices',
  BYOD_SPRINT: 'byodSprint',
  ACTIVATE: 'activate',
  MYACCOUNT: 'myaccount',
  ACCOUNT: 'account',
  DATA_SETUP: 'dataSetup',
  ABOUT_G2G: 'aboutgood2go',
  ABOUT_G2G_CAP: 'AboutGood2Go',
  HEARING_AID_COMPATIBILITY: 'HearingAidCompatibility',
  LEGACY_LOGIN: 'legacy-login',
  FIND_STORE: 'findstore',
  FORGET_PASSWORD: 'forget-password',
  LOGIN: 'login',
  WELCOME: 'welcome',
  SITEMAP: 'sitemap',
  UNLOCKING_POLICY: 'unlockingPolicy',
  PRIVACY_POLICY: 'privacyPolicy',
  HOWITWORKS: 'HowItWorks',
  HOW_TO_SWITCH: 'howdoiswitchnumbers',
  BROADBAND: 'broadbandDisclosure',
  STORE: 'store',
  BYOD: 'byod',
  CARD_LINKING: 'card-linking',
  SHUTDOWN_3G: '3G_shutdown',
  SUPPORT:'support'
};
