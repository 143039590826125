import { Injectable } from '@angular/core';

import { SimpleAuthService } from '@ztarmobile/zwp-services-auth';
import { Observable } from 'rxjs';
import { RouterHelperService } from '@ztarmobile/zwp-services-util';
import { map, take } from 'rxjs/operators';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class AuthGuardService  {
  constructor(private router: RouterHelperService, private simpleAuthService: SimpleAuthService) {
  }

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const nextPage = route.data['nextPage'] || 'home';
    return this.simpleAuthService.userState.pipe(map((authState) => {
      if (!!authState && !authState.isAnonymous) {
        if (typeof nextPage === 'string' && nextPage.trim()) {
          this.router.navigate(['/' + nextPage]);
        }
        return false;
      } else {
        return true;
      }
    }), take(1));
  }
}

export const AUTH_GUARD_PROVIDERS = [
  AuthGuardService
];
