import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserAccountService } from '@ztarmobile/zwp-service-backend';
import { ToastrHelperService } from '../../services/toast-helper.service';
import { PlatformLocation } from '@angular/common';
import { getAuth, PhoneAuthProvider, RecaptchaVerifier, updatePhoneNumber } from 'firebase/auth';
import { AppState } from '@app/app.service';
import { Subscription } from 'rxjs';
import { ModalHelperService } from '@services/modal-helper.service';
import { CAPTCHA_BOT_ERROR_CODE } from '@app/app.config';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export class ActivatedNumberModalContext {
  public customClass?: string;
  public label?: string;
  public title?: string;
}

@Component({
  selector: 'app-add-activated-number-modal',
  styleUrl: 'add-activated-number-modal.component.scss',
  templateUrl: './add-activated-number-modal.component.html'
})
export class AddActivatedNumberModalComponent implements OnDestroy {
  public context: any;
  public activatingPhoneForm: FormGroup;
  public verificationForm: FormGroup;
  public waitingConfirmCode = false;
  public successResult = false;
  public failureResult = false;
  public customFailureResult = false;
  public phoneNumber;
  public recaptchaResolvedSubscription: Subscription;

  private verificationId: string;
  private recaptchaVerifier;
  private invisibleCaptchaRes;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<ActivatedNumberModalContext>,
    private toastHelper: ToastrHelperService,
    private userAccountService: UserAccountService,
    private location: PlatformLocation,
    private formBuilder: FormBuilder,
    private appState: AppState, private modalHelper: ModalHelperService) {
    location.onPopState(() => { this.beforeDismiss(); this.dialog.close(); });
    this.context = data;
    this.activatingPhoneForm = this.formBuilder.group({
      phoneNumber: ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])],
    });
    this.verificationForm = this.formBuilder.group({
      verificationCode: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6)])],
    });
  }

  beforeClose(): boolean {
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    }
    return false;
  }

  ngOnDestroy(): void {
    if (this.recaptchaResolvedSubscription) {
      this.recaptchaResolvedSubscription.unsubscribe();
    }
  }

  beforeDismiss(): boolean {
    return this.beforeClose();
  }

  cancel(): void {
    this.beforeDismiss();
    this.dialog.close();
  }

  public sendCode(): void {
    const action = 'g2g_validate_mdn';
    this.activatingPhoneForm.markAllAsTouched();
    if (!!this.activatingPhoneForm?.valid) {
      this.appState.loading = true;
      this.appState.resetAndExecuteCaptchaSubject.next({ action });
      this.recaptchaResolvedSubscription = this.appState.resolvedCaptcha.subscribe(response => {
        this.invisibleCaptchaRes = response;
        if (!!this.invisibleCaptchaRes) {
          this.recaptchaResolvedSubscription?.unsubscribe();
          this.callIsValidPhoneNumber(this.invisibleCaptchaRes, true, action);
        }
      });
    }
  }

  public callIsValidPhoneNumber(captchaRes, invisibleCaptchaRes, action?): void {
    this.phoneNumber = this.activatingPhoneForm.controls.phoneNumber.value;
    this.userAccountService.isValidPhoneNumber(this.phoneNumber, captchaRes, !!action ? action : null, invisibleCaptchaRes).then(() => {
      this.sendVerificationCode();
    }, (error) => {
      this.appState.loading = false;
      this.recaptchaResolvedSubscription?.unsubscribe();
      if (error?.code === CAPTCHA_BOT_ERROR_CODE) {
        this.modalHelper.showRecaptchaVerificationModal().afterClosed().subscribe((result) => {
          if (!!result) {
            this.appState.loading = true;
            this.callIsValidPhoneNumber(result, false);
          }
        })
      } else {
        this.toastHelper.showAlert(error.message);
      }
    });
  }

  public verifyLoginCode(): void {
    this.verificationForm.markAllAsTouched();
    if (!!this.verificationForm?.valid) {
      this.appState.loading = true;
      const auth = getAuth();
      const phoneAuthProvider = PhoneAuthProvider;
      const cred = phoneAuthProvider.credential(this.verificationId, this.verificationForm?.controls?.verificationCode?.value);
      updatePhoneNumber(auth.currentUser, cred).then(() => {
        this.appState.loading = false;
        this.successResult = true;
        this.failureResult = false;
        this.customFailureResult = false;
        this.done();
      }).catch((error) => {
        this.appState.loading = false;
        this.successResult = false;
        this.failureResult = true;
        if(error?.message?.includes('auth/invalid-verification-code')) {
          this.customFailureResult = true;
        } else {
          this.customFailureResult = false;
        }
      });
    }
  }

  public done(): void {
    this.dialog.close(this.phoneNumber);
  }
  public recheckIntries(): void {
    this.customFailureResult = false;
    this.successResult = false;
    this.failureResult = false;
    this.verificationForm.reset();
    this.waitingConfirmCode = true;
  }

  private sendVerificationCode(): void {
    const auth = getAuth();
    const phoneAuthProvider = new PhoneAuthProvider(auth);
    //clear captcha container 
    const recaptchaContainer = document.getElementById('recaptchaContainer');  
    setTimeout(() => {
      if (recaptchaContainer) {
        recaptchaContainer.innerHTML = ''; // This prevents duplicate reCAPTCHA elements
      }  
      this.recaptchaVerifier = new RecaptchaVerifier('recaptchaContainer', {
        size: 'invisible',
        callback: () => {
        }
      }, auth);
      phoneAuthProvider.verifyPhoneNumber(`+1${this.phoneNumber}`, this.recaptchaVerifier).then((result) => {
        this.verificationId = result;
        this.waitingConfirmCode = !!result;
        this.appState.loading = false;
      }).catch((error) => {
        this.appState.loading = false;
        this.recaptchaResolvedSubscription?.unsubscribe();
        this.waitingConfirmCode = false;    
        this.toastHelper.showAlert(error.message);
      });
    }, 1000);
  }
}

