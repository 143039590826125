import { PlatformLocation } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EMAIL_PATTERN } from '@app/app.config';

export class emailChangeModalContext {
  oldEmail: string;
  customClass?: string;
  title: string;
  message: string;
  okBtnText?: string;
  cancelBtnText?: string;
}

@Component({
  selector: 'app-email-change-modal',
  styleUrl: './email-change-modal.component.scss',
  templateUrl: './email-change-modal.component.html'
})
export class emailChangeModalComponent {
  public emailForm: FormGroup;
  public context: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: emailChangeModalContext,
    public dialog: MatDialogRef<emailChangeModalContext>,
    private location: PlatformLocation,
    formBuilder: FormBuilder
  ) {
    location.onPopState(() => { this.beforeClose(); this.dialog.close(); });
    this.context = data;
    this.emailForm = formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(EMAIL_PATTERN), this.forbiddenEmailValidator(this.context.oldEmail)]],
    });
  }

  forbiddenEmailValidator(forbiddenEmail: string) {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value && control.value.toLowerCase() === forbiddenEmail.toLowerCase()) {
        return { forbiddenEmail: { value: control.value } };
      }
      return null;
    };
  }

  beforeClose(): boolean {
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    }
    return false;
  }

  closeDialog(result?): void {
    this.emailForm.markAllAsTouched();
    if (result && !this.emailForm.valid) {
      return;
    }

    this.beforeClose();
    this.dialog.close(result);
  }

  public submitEmail() {
    this.emailForm.markAllAsTouched();
    if (this.emailForm.valid) {
      this.closeDialog(this.emailForm.controls.email.value);
    }
  }
}
