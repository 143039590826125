<div class="modal">
<div class="container model-container {{settings.customClass || ''}}">
  <div class="modal-header">
    <h4 class="modal-heading">{{settings.title || ''}}</h4>
    <button id="close-popup-btn" data-cy="closeIcon" class="modal-close modal-toggle" *ngIf="!settings.hideCloseIcon" (click)="closeDialog()">
      <i class="icon-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-content">
      <div class="row" *ngIf="!!settings?.customHTML || !!context?.message">
        <div *ngIf="!settings.enableHTML">
          {{context.message}}
        </div>
        <div *ngIf="settings.enableHTML" [innerHTML]="context.message"></div>
        <div *ngIf="!!settings.customHTML" [innerHTML]="settings.customHTML"></div>
      </div>
      <div class="modal-actions">
        <button id="confirm-btn" data-cy="confirm-btn" class="button {{settings?.invertButtonColors? 'secondary' : 'primary'}}" (click)="OK()">
          {{settings.okText || 'OK'}}
        </button>
        <button id="cancel-btn" data-cy="cancel-btn" class="button {{settings?.invertButtonColors? 'primary' : 'secondary'}}" (click)="cancel()"
          *ngIf="settings.cancelText">
          {{settings.cancelText}}
        </button>
      </div>
    </div>
  </div>
</div>
